<template>
    <div>
        <div class="desktopLayoutClass">
            <v-app style="background-color: white;">
                <!-- Navbar Component -->
                <NavBar :username="customerName" :pageName="pageName"></NavBar>

                <!-- authErrorFlag Dialog Start -->
                <div justify="center">
                    <v-dialog v-model="authErrorFlag" persistent max-width="290">
                        <v-card>
                            <v-card-title style="word-break:normal">
                                {{ $t("Warning_Message.Session_Expired_Please_Login_Again") }}
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" depressed @click="closeAuthErrorDialog" style="background-color: #1467BF;">
                                    {{ $t("Warning_Message.Login") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <!-- authErrorFlag Dialog End -->

                <!-- errorFlag Dialog Start -->
                <div justify="center">
                    <v-dialog v-model="errorFlag" persistent max-width="290">
                        <v-card>
                            <v-card-title style="word-break:normal">
                                {{ $t("Warning_Message.Something_Went_Wrong") }}
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn depressed
                                    color="primary" @click="errorFlag = false" style="background-color: #1467BF;">
                                    {{$t("Warning_Message.Ok")}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <!-- errorFlag Dialog End -->

                <!-- restartAppFlag Dialog Start -->
                <div>
                    <v-row justify="center">
                        <v-dialog v-model="restartAppFlag" persistent max-width="290">
                            <v-card>
                                <v-card-title style="word-break:normal">
                                    {{ $t("Warning_Message.Restart_App") }}
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn depressed color="primary" @click="restartAppFlag = false;">
                                        {{ $t("Warning_Message.Ok") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </div>
                <!-- restartAppFlag Dialog End -->

                <!-- trustAlertFlag Dialog Start -->
                <div>
                    <v-row justify="center">
                        <v-dialog v-model="trustAlertFlag" persistent max-width="290">
                            <v-card>
                                <v-card-title style="word-break:normal">
                                    {{ $t("Warning_Message.Only_Trust") }}
                                </v-card-title>
                                <v-card-actions>
                                    <div style="display:flex; flex-direction:row-reverse; justify-content: space-between; width:100%">
                                        <div>
                                            <v-btn @click="redirectToKBZPayUpgrade" depressed color="primary">
                                                {{ $t("Warning_Message.Upgrade") }}
                                            </v-btn>
                                        </div>
                                        <div>
                                            <v-btn @click="closeMyMedicine" depressed color="error" >
                                                {{ $t("Warning_Message.Cancel") }}
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </div>
                <!-- trustAlertFlag Dialog End -->

                <!-- appVersionFlag Dialog Start -->
                <div v-show="appVersionFlag">
                    <v-row justify="center">
                        <v-dialog v-model="appVersionFlag" persistent max-width="290">
                            <v-card>
                                <v-card-title style="word-break:normal">
                                    <p> {{ $t("Warning_Message.AppUpdatePromptVideoCall") }} </p>
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" depressed @click="closeAppVersionFlag" style="background-color: #1467BF;">
                                        {{ $t("Warning_Message.Update") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </div>
                <!-- appVersionFlag Dialog End -->
                
                <div class="d-flex ma-1 mt-2" style="padding-left: 14px; padding-right: 14px;">
                    <div style="width:100%">
                        <v-text-field
                            height="45px"
                            class="mt-4"
                            v-model="searchString" 
                            placeholder="Search"
                            @click="goToSearchPage()"
                            @keyup.enter="goToSearchPage()"
                            @click:append="goToSearchPage()"
                            outlined
                            dense
                            hide-details="auto"
                            @click:clear="searchString = ''"
                            >
                            <template #append class="align-items-center">
                                <div class="custom-icon" style="background-image: url('https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/blueSearch.svg')"></div>
                            </template>
                        </v-text-field>
                    </div>
                    
                </div>
                <div v-if="loadingFlag">
                    <!-- Banner Component -->
                    <div class="mt-1 px-2 py-2">
                        <div>
                            <v-skeleton-loader type="image" height="160px"></v-skeleton-loader>
                        </div>
                    </div>

                    <v-divider></v-divider>

                    <!-- Upcoming Appointments -->
                    <div v-if="loadingAppointments" class="px-2 py-2 rounded-lg">
                        <div>
                            <div class="pl-1">
                                <v-skeleton-loader type="heading"></v-skeleton-loader>
                            </div>
                        </div>

                        <div class="pa-1">
                            <div class="d-flex justify-space-between align-center">
                                <div class="d-flex justify-space-around align-center">
                                    <div class="pa-2">
                                        <v-skeleton-loader type="avatar" max-width="300"></v-skeleton-loader>
                                    </div>
                                    <div class="pl-2">
                                        <v-skeleton-loader type="text" width="150px"></v-skeleton-loader>
                                    </div>
                                </div>

                                <div>
                                    <v-skeleton-loader type="button" max-width="200"></v-skeleton-loader>
                                </div>
                            </div>

                            <div class="d-flex justify-space-between align-center">
                                <v-skeleton-loader class="mx-auto" type="text" width="100px"></v-skeleton-loader>
                                <v-skeleton-loader class="mx-auto" type="text" width="100px"></v-skeleton-loader>
                            </div>
                        </div>
                        
                        <v-skeleton-loader class="mx-auto py-2" type="text" width="300px"></v-skeleton-loader>
                    </div>

                    <v-divider></v-divider>

                    <!-- Specializations Component -->
                    <div class="px-2 py-2">
                        <div class="pa-1">
                            <v-skeleton-loader type="heading"></v-skeleton-loader>
                        </div>
                        <div class="d-flex flex-wrap align-center pa-1 specializationComponentClass">
                            <div v-for="i in 'string'" :key="i">
                                <div class="pa-1" width="33%">
                                    <v-skeleton-loader type="image" width="110px" height="110px"></v-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <v-divider></v-divider>

                    <!-- Walk In Component -->
                    <div class="px-2 py-2">
                        <div class="d-flex justify-space-between align-center pa-1">
                            <div class="d-flex align-center">
                                <div>
                                    <v-skeleton-loader type="image" width="32px" height="32px"></v-skeleton-loader>
                                </div>
                                
                                <div class="pl-2" style="width: 60%">
                                    <v-skeleton-loader type="heading" width="200px"></v-skeleton-loader>
                                </div>
                            </div>

                            <div>
                                <v-skeleton-loader type="heading" width="150px"></v-skeleton-loader>
                            </div>
                        </div>

                        <div class="pa-1">
                                <div class="d-flex justify-start align-center">
                                    <v-skeleton-loader class="pa-2" type="image" width="150px" height="200px"></v-skeleton-loader>
                                    <v-skeleton-loader class="pa-2" type="image" width="150px" height="200px"></v-skeleton-loader>
                                </div>
                        </div>
                    </div>

                    <v-divider></v-divider>

                    <!-- Feature Speciality -->
                    <div class="px-2 py-2">
                        <div>
                            <v-skeleton-loader type="image" height="160px"></v-skeleton-loader>
                        </div>
                    </div>

                    <v-divider></v-divider>

                    <!-- Care Companion -->
                    <div class="mb-2 px-2 py-2">
                        <div class="d-flex justify-space-between align-center pa-1">
                            <div class="d-flex align-center">
                                <div>
                                    <v-skeleton-loader type="image" width="32px" height="32px"></v-skeleton-loader>
                                </div>
                                
                                <div class="pl-2" style="width: 60%">
                                    <v-skeleton-loader type="heading" width="200px"></v-skeleton-loader>
                                </div>
                            </div>

                            <div>
                                <v-skeleton-loader type="image" width="32px" height="32px"></v-skeleton-loader>
                            </div>
                        </div>

                        <div class="pa-2 mb-4">
                            <v-skeleton-loader align="center" type="image" width="100%" height="40px"></v-skeleton-loader>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div align="center">
                        <!-- Banner Component -->
                        <div class="pa-4">
                            <banner :banners="banners"></banner>
                        </div>

                        <v-divider></v-divider>
                        <div style="padding: 16px; cursor: pointer;" v-if="activePackageFlag" @click="navigateToPackages">
                            <div></div>
                            <div class="activePackageIndicationBanner">
                                <div>
                                    <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/package_indication_icon.svg" />
                                </div>
                                <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                                    <div class="activePackageText">{{$t("Customer.Home.ActivePackage")}}</div>
                                    <div style="cursor: pointer;">
                                        <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/package_indication_arrow.svg"/>
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <!-- Upcoming Appointments -->
                        <div v-if="!loadingAppointments">
                            <div class="pa-4 rounded-lg" v-if="appointmentsList.length > 0">
                                <div class="pb-4" align="left">
                                    <span class="ma-0 heading">
                                        {{ $t("Customer.Home.Upcoming_Consultations") }}
                                    </span>
                                </div>

                                <v-card elevation="0" style="border: 1px solid #E0E0E0;">
                                    <v-carousel hide-delimiters :continuous="true" :cycle="true" :show-arrows="false" height="auto"
                                    >
                                        <div v-for="appointment in appointmentsList" :key="appointment._id">
                                            <v-carousel-item>
                                                <appointmentComponent :appointment_data="appointment"></appointmentComponent>
                                            </v-carousel-item>
                                        </div>
                                    </v-carousel>
                                </v-card>
                            </div>
                        </div>
                        <div v-else class="px-2 py-2 rounded-lg">
                            <div>
                                <div class="pl-1" align="left">
                                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                                </div>
                            </div>

                            <div class="pa-1">
                                <div class="d-flex justify-space-between align-center">
                                    <div class="d-flex justify-space-around align-center">
                                        <div class="pa-2">
                                            <v-skeleton-loader type="avatar" max-width="300"></v-skeleton-loader>
                                        </div>
                                        <div class="pl-2">
                                            <v-skeleton-loader type="text" width="150px"></v-skeleton-loader>
                                        </div>
                                    </div>

                                    <div>
                                        <v-skeleton-loader type="button" max-width="200"></v-skeleton-loader>
                                    </div>
                                </div>

                                <div class="d-flex justify-space-between align-center">
                                    <v-skeleton-loader class="mx-auto" type="text" width="100px"></v-skeleton-loader>
                                    <v-skeleton-loader class="mx-auto" type="text" width="100px"></v-skeleton-loader>
                                </div>
                            </div>
                        
                            <v-skeleton-loader class="mx-auto py-2" type="text" width="300px"></v-skeleton-loader>
                        </div>

                        <div v-if="appointmentsList.length > 0">
                            <v-divider></v-divider>
                        </div>

                        <!-- Specializations Component -->
                        <div class="pa-4" v-if="specializationList.length > 0">
                            <div class="pb-4" align="left">
                                <span class="heading">{{ $t("Customer.Home.Explore_By_Specialities") }}</span>
                            </div>
                            <specializationsComponent :specialization_list="specializationList" :language="language" ref="specializationsComponent" :pageName="pageName"></specializationsComponent>
                        </div>

                        <div v-if="specializationList.length > 0">
                            <v-divider></v-divider>
                        </div>

                        <!-- Walk In Component -->
                        <div class="pa-4" v-if="walkInDoctorList.length > 0 && !isWalkinLoading">
                            <div class="white">
                                <div class="d-flex justify-space-between align-center py-1 pb-4">
                                    <img style="width: 32px; height: 32px;" src="https://s3iconimages.mymedicine.com.mm/quickConsultationIcon.svg" alt="Featured Icon">
                                    
                                    <span class="ml-2 heading" style="width: 70%; text-align: left;">{{ $t("Customer.Home.Quick_Consultation") }}</span>

                                    <div class="d-flex justify-space-around align-center" @click="redirectToWalkInDoctors()">
                                        <div>
                                            <span class="subheading">{{ $t("Customer.Home.View_More") }}</span>
                                        </div>
                                        <div class="pl-2">
                                            <img src="https://s3iconimages.mymedicine.com.mm/view_more_arrow.svg" />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <v-slide-group v-model="selectedDoctor">
                                        <v-slide-item v-for="walkInDoctor in walkInDoctorList" :key="walkInDoctor._id">
                                            <v-card class="mr-2 rounded-lg my-1" elevation="0" style="width: 9.5rem; min-height: 250px; border: 1px solid #C7E2FF;">
                                                <div class="d-flex justify-start">
                                                    <img style="width: 100%; height: 92px; border-top-left-radius: 8px; border-top-right-radius: 8px; object-fit: contain;" :src="walkInDoctor.doctor_profile_picture" alt="Doctor Image">
                                                </div>
                                                <div class="offer_indication" v-if="walkInDoctor.offer_length && walkInDoctor.offer_length > 0">
                                                    <img src="https://s3iconimages.mymedicine.com.mm/percentage_logo.svg" />
                                                    <span class="offer_text">{{walkInDoctor.offer_length}} {{$t("Customer.ViewDoctorsPage.Offer")}} </span>
                                                </div>
                                                <!-- <div class="d-flex justify-start" v-else>
                                                    <img style="width: 100%; height: 92px; border-top-left-radius: 8px; border-top-right-radius: 8px; object-fit: contain;" src="https://s3iconimages.mymedicine.com.mm/doctor_dp.svg" alt="Doctor Image">
                                                </div> -->

                                                <div style="height: 150px; background-color: white; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;">
                                                    <div class="d-flex flex-column align-contents-strech pa-2" style="justify-content: space-evenly; height: 100%;">
                                                        <div class="flex-grow-1" align="left">
                                                            <span class="heading line-clamp"><b>{{ walkInDoctor.doctor_name }}</b></span>
                                                        </div>
                                                        
                                                        <div class="flex-shrink-0 text-truncate" align="left">
                                                            <span class="subheading">{{ walkInDoctor.specialization_name }}</span>
                                                        </div>
                                                        
                                                        <div class="flex-shrink-0 d-flex flex-column align-items-center">
                                                            <div class="pb-1" align="left">
                                                                <span class="subheading" style="color: #219653;"> {{ walkInDoctor.total_fee }} {{ $t("Customer.Home.MMK") }} </span>
                                                            </div>

                                                            <v-btn color="primary" elevation="0" style="text-transform: none;background-color: #1467BF;" @click="redirectToNewDoctorsPage(walkInDoctor._id)">
                                                                <span class="normaltext">{{ $t("Customer.Home.View_Slot") }}</span>
                                                            </v-btn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-card>
                                        </v-slide-item>
                                    </v-slide-group>
                                </div>
                            </div>
                        </div>

                        <div class="px-2 py-2" v-else-if="isWalkinLoading">
                            <div class="d-flex justify-space-between align-center pa-1">
                                <div class="d-flex align-center">
                                    <div>
                                        <v-skeleton-loader type="image" width="32px" height="32px"></v-skeleton-loader>
                                    </div>
                                    
                                    <div class="pl-2" style="width: 60%">
                                        <v-skeleton-loader type="heading" width="200px"></v-skeleton-loader>
                                    </div>
                                </div>

                                <div>
                                    <v-skeleton-loader type="heading" width="150px"></v-skeleton-loader>
                                </div>
                            </div>

                            <div class="pa-1">
                                    <div class="d-flex justify-start align-center">
                                        <v-skeleton-loader class="pa-2" type="image" width="150px" height="200px"></v-skeleton-loader>
                                        <v-skeleton-loader class="pa-2" type="image" width="150px" height="200px"></v-skeleton-loader>
                                    </div>
                            </div>
                        </div>

                        <div v-if="walkInDoctorList.length > 0">
                            <v-divider></v-divider>
                        </div>

                        <!-- Highlight Speciality Component Banner -->
                        <div class="pa-4" v-if="highlightSpecialityBanners.length > 0">
                            <banner :banners="highlightSpecialityBanners" @click="highlightSpecialitySelected()" ></banner>
                        </div>

                        <div v-if="highlightSpecialityBanners.length > 0">
                            <v-divider></v-divider>
                        </div>

                        <div class="pa-4" v-if="ServiceDoctors.length > 0">
                            <div class="white">
                                <div class="d-flex justify-space-between align-center py-1 pb-4">
                                    <img style="width: 32px; height: 32px;" src="https://s3iconimages.mymedicine.com.mm/quickConsultationIcon.svg" alt="Featured Icon">
                                    
                                    <span class="ml-2 heading" style="width: 70%; text-align: left;">{{ $t("Customer.Home.Old_Service_Doctors") }}</span>

                                    <div class="d-flex justify-space-around align-center" @click="redirectToServiceDoctors()">
                                        <div>
                                            <span class="subheading">{{ $t("Customer.Home.View_More") }}</span>
                                        </div>
                                        <div class="pl-2">
                                            <img src="https://s3iconimages.mymedicine.com.mm/view_more_arrow.svg" />
                                        </div>
                                    </div>
                                </div>
                                <div v-for="doctor in ServiceDoctors" :key="doctor._id" style="padding-top:10px; ">
                                    <doctorsComponent :doctor="doctor" :pageName="analyticsPageName"></doctorsComponent>
                                </div>
                            </div>
                        </div>

                        <div v-if="ServiceDoctors.length > 0">
                            <v-divider></v-divider>
                        </div>

                        <!-- Care Companion -->
                        <div class="mb-2 pa-4" style="height: 30vh;">
                            <div class="white">
                                <div class="d-flex justify-space-between align-center py-1 pb-4">
                                    <img align="left" src="https://s3iconimages.mymedicine.com.mm/cc_icon.svg" alt="Care Companion">
                                    
                                    <span class="ml-1 heading" style="width: 83%; text-align: left;">{{ $t("Customer.Home.Care_Companion") }}</span>

                                    <div>
                                        <img align="right" src="https://s3iconimages.mymedicine.com.mm/info.svg" alt="Info Icon" @click="redirectCareComapnionInfo()"/>
                                    </div>
                                </div>

                                <div class="pa-2">
                                    <v-btn color="primary" style="width: 95%;background-color: #1467BF;" @click="redirectToCareCompanion()">
                                        <img class="mr-1 pa-1" src="https://s3iconimages.mymedicine.com.mm/phone_cc.svg" alt="Call Icon">
                                        <span class="heading">{{ $t("Customer.Home.Request_Call") }}</span>
                                    </v-btn>
                                </div>
                            </div>
                        </div>

                        <!-- Bottom Navbar Component -->
                        <!-- <div class="mb-2 py-2">
                            <bottomNavBar style="position: sticky; bottom: 0px; width: 100%; max-height: 50px;" :pageName=pageName :isLoggedIn=token></bottomNavBar>
                        </div> -->
                        <div style="padding:3rem 0px !important" class="mb-2 bottom-navbar-segment mobile-bottom-nav-bar">
                            <bottom-nav-bar-component :selected-option="2"/>
                        </div>
                    </div>
                    <v-overlay :absolute="absolute" :value="isOverlay">
                    </v-overlay>
                </div>
            </v-app>
        </div>
    </div>
</template>

<script>
import * as heatmap from 'heatmap.js';
import axios from "axios";
import BottomNavBarComponent from '../../../components/nurseBookingPageComponents/bottomNavBarComponent.vue';
export default {
    name: 'CustomerHomeScreen',
    components: {
        NavBar: () => import('../../../views/navbar.vue'),
        banner: () => import('../../../components/bannersComponent.vue'),
        appointmentComponent: () => import('../../../components/appointmentComponent.vue'),
        specializationsComponent: () => import('../../../components/specializationsComponent.vue'),
        bottomNavBar: () => import('./bottomnavbar.vue'),
        doctorsComponent: () => import('../../../components/doctorsComponent.vue'),
        'bottom-navigation-bar': () => import('../../../components/navigationComponents/bottomNavigationBar.vue'),
        BottomNavBarComponent,
    },
    data() {
        return {
            hi:"",
            token: '',
            kbzAppToken: '',
            customerName: '',
            pageName: 'CustomerHomeScreen',
            analyticsPageName:'CustomerHomeScreen_serviceDoctors',
            language: '',
            banners: [],
            appointmentsList: [],
            specializationList: [],
            walkInDoctorList: [],
            newUserFlag: false,
            trustLevelFlag: '',
            consentAcceptanceFlag: false,
            showWalkInFlag: false,
            authErrorFlag: false,
            restartAppFlag: false,
            errorFlag: false,
            appVersionFlag: false,
            appFlag: false,
            eligibilityStatus: '',
            trustAlertFlag: false,
            loadingFlag: true,
            loadingAppointments: true,
            bannersList: [],
            highlightSpecialityBannersList: [],
            highlightSpecialityBanners: [],
            selectedDoctor: '',
            ServiceDoctors: [],
            showspecialization:true,
            walkInDoctorsListBurmese:[],
            walkInDoctorsListEnglish:[],
            specializationlistINBothLanguages:[],
            serviceDoctorsInBothLanguages:[],
            loggenInFlag: false,
            isWalkinLoading: true,
            isOverlay: false,
            absolute: true,
            searchString: '',
            activePackageFlag: false,
            searchIconURL: 'https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/searchbutton.svg'
        };
    },
    watch: {
        language: function(newval,oldval){
            if(this.language == 'en') {
                this.setLanguageForOnloginScreen('en');
                if(this.$refs.specializationsComponent){
                    this.$refs.specializationsComponent.updatespecialization(this.specializationList);
                }
            } else if (this.language == 'mm') {
                this.setLanguageForOnloginScreen('mm');
                if(this.$refs.specializationsComponent){
                    this.$refs.specializationsComponent.updatespecialization(this.specializationList);
                }
            } else {
                let thisLanguageSpecialization = []
                if(this.specializationList && this.specializationList.length>0){
                  this.specializationList.forEach((item)=>{
                    let everyRecord = {
                      image_filename: item.image_filename,
                      specialization_key: item.specialization_key,
                      specialization_name:item.specialization_name_mm,
                      _id: item._id
                    };
                    thisLanguageSpecialization.push(everyRecord);
                  })
                  this.specializationList = thisLanguageSpecialization;
                }
            }
        }
    },
    mounted() {
        // Token.
        this.token = this.$cookies.get("customerToken");
        
        // Username.
        if(this.$store.state.name !== "") {
            this.customerName = this.$store.state.name;
        }
        // URL Params.
        if(this.$route.query)
            this.kbzAppToken = this.$route.query.kbzpay_token;

        // Language.
        if (this.$route.query.lang && ["en","my"].includes(this.$route.query.lang)) {
            this.$i18n.locale = this.$route.query.lang == "my" ? "mm" : this.$route.query.lang;
            this.$store.dispatch("changeLocale", this.$i18n.locale);
        }
        if (this.$store.state.locale !== "") {
            this.$i18n.locale =  this.$store.state.locale;
            this.language = this.$i18n.locale;
        } else {
            this.$i18n.locale = "mm";
            this.$store.dispatch("changeLocale", this.$i18n.locale);
            this.language = this.$i18n.locale;
        }

        // Screen Resolution.
        if (window.xm) {
            document.title = '';
        } else {
            if (this.$store.state.locale == "en") {
                document.title = "Home"
            } else {
                document.title = "ပင်မ"
            }
        }

        //Delete booking data
        localStorage.removeItem('BookingDetails');
        //Delete Old Search String
        localStorage.removeItem('lastSearchedString');

        // HeatMap.
        this.$el.addEventListener('click', this.onClick);

        // User Agent.
        let userAgent = navigator.userAgent;
        if (userAgent.includes('kbzpay')) {
          this.$store.dispatch('changeUserType', 'APP');
          this.appFlag = true;
        } else {
          this.$store.dispatch('changeUserType', 'MOBILE');
        }
        // Customer Home Screen API call.
        if (this.kbzAppToken || this.token) {
            // Logger API Inputs.
            this.isOverlay = true;
            let currentURL =  localStorage.getItem("currentURL");
            if( currentURL == this.pageName) {
                currentURL = "";
            }
            localStorage.setItem("currentURL", this.pageName);

            let customerHomeScreenRequest = {
                kbzAppToken: this.kbzAppToken,
                loginToken: !this.token ? "" : this.token
            };

            axios.post(process.env.VUE_APP_BACKEND_URL + '/fetchHomeScreenData', customerHomeScreenRequest)
            .then((customerHomeScreenResponse) => {
                // Set Token.
                this.$cookies.set('customerToken', customerHomeScreenResponse.data.data.token, '1w');
                this.token = customerHomeScreenResponse.data.data.token;
                
                //activePackageFlag
                this.activePackageFlag = customerHomeScreenResponse.data.data.packageFlag

                // Customer Data.
                this.customerName = customerHomeScreenResponse.data.data.customer_data.customer_name;
                this.language = customerHomeScreenResponse.data.data.customer_data.language;

                // Upcoming Appointments.
                this.loadingAppointments = true;
                let fetchUpcomingAppointmentsRequest = {
                    token: this.token,
                    typeOfUser: 'CUSTOMER'
                }
                
                // Set Flag
                this.loggenInFlag = true;

                // Heat Map Check.
                if(customerHomeScreenResponse.data.showHeatMap){
                    localStorage.setItem('showHeatMap', true);
                    this.drawHeatMap();
                } else {
                    localStorage.removeItem('showHeatMap');
                }

                this.$cookies.set('verifiedStatus', this.eligibilityStatus);

                if (this.kbzAppToken) {
                    if (this.language != this.$i18n.locale && this.$route.query.lang && ['en','my'].includes(this.$route.query.lang))
                        this.changeLanguage(this.$i18n.locale, this.token);
                } else if(this.language != this.$i18n.locale) {
                    this.$i18n.locale = this.language;
                    this.$store.dispatch('changeLocale', this.$i18n.locale);
                }

                // Logging API Invocations?

                if (this.$store.state.locale != this.$i18n.locale) {
                    this.$store.dispatch('changeLocale', this.$i18n.locale);
                }

                if (this.$store.state.name != this.customerName) {
                    this.$store.dispatch('changeName', this.customerName);
                }

                // Walk In Doctors.
                if (this.walkInDoctorList.length > 0) {
                    this.showWalkInFlag = true;
                }

                this.loadingFlag = false;

                axios.post(process.env.VUE_APP_BACKEND_URL + '/fetchUpcomingAppointments', fetchUpcomingAppointmentsRequest)
                .then((fetchUpcomingAppointmentsResponse) => {
                    this.appointmentsList = (fetchUpcomingAppointmentsResponse.data.data.length > 0) ? fetchUpcomingAppointmentsResponse.data.data : [];

                    // Upcoming Appointments.
                    if (this.appointmentsList.length > 0) {
                        this.appointmentsList.forEach((appointment) => {
                            let dateNow = new Date();
                            let getDiff = new Date(appointment.booking_epoch_time).getTime() - dateNow.getTime()
                            let endDiff = new Date((appointment.booking_closing_epoch_time)) - dateNow.getTime()
                            appointment.isLinkActive = (getDiff < 600000) ? true : false;
                        });
                    }
                
                    this.loadingAppointments = false;
                })
                .catch((fetchUpcomingAppointmentsError) => {
                    if (fetchUpcomingAppointmentsError.response.status == 401) {
                        this.authErrorFlag = true;
                    } else if (fetchUpcomingAppointmentsError.response.status == 400) {
                        let userAgent = navigator.userAgent;
                        if (window.xm || userAgent.includes('kbzpay')) {
                            this.restartAppFlag = true;
                        } else {
                            this.errorFlag = true;
                        }
                    } else {
                        this.errorFlag = true;
                    }
                });
                this.userPageViewTable();
                this.isOverlay = false;
            })
            .catch((CustomerHomeScreenError) => {
                if (CustomerHomeScreenError.response.status == 401) {
                    this.authErrorFlag = true;
                } else if (CustomerHomeScreenError.response.status == 406) {
                    this.restartAppFlag = true;
                } else if (CustomerHomeScreenError.response.status == 400) {
                    let userAgent = navigator.userAgent;
                    if (window.xm || userAgent.includes('kbzpay')) {
                        this.restartAppFlag = true;
                    } else {
                        this.$cookies.remove("customerToken");
                        this.authErrorFlag = true;
                    }
                } else if (CustomerHomeScreenError.response.status == 403) {
                    this.trustAlertFlag = true;
                } else {
                    this.errorFlag = true;
                }
                this.isOverlay = false;
            });
            this.getDoctorsInfo("/getHomePageDoctors", null);
        } else {
            // Clear Local Storage.
            this.$store.dispatch('cleanVuex');
            this.customerName = '';
            this.getDoctorsInfo("/getHomePageDoctors", null);
            this.userPageViewTable();
        }
    },
    methods: {
        setLanguageForOnloginScreen(customerLanguage) {
            let thisLanguageSpecialization=[];
            this.specializationList=[];
            this.specializationlistINBothLanguages.forEach((item)=>{
                let everyRecord = {
                  image_filename: item.image_filename,
                  specialization_key: item.specialization_key,
                  specialization_name:customerLanguage === 'en' ? item.specialization_name : item.specialization_name_mm,
                  _id: item._id
                }
                thisLanguageSpecialization.push(everyRecord);
            });
            this.specializationList = thisLanguageSpecialization;
            this.walkInDoctorList = [];
            this.walkInDoctorList = customerLanguage === 'en' ? this.walkInDoctorsListEnglish : this.walkInDoctorsListBurmese;
            if (customerLanguage === 'en') {
                let thisLanguageServiceDoctors = [];
                this.ServiceDoctors=[];
                if(this.serviceDoctorsInBothLanguages)
                {
                    this.serviceDoctorsInBothLanguages.forEach((item)=>{
                        let everyRecord = {
                            doctor_id: item.doctor_id,
                            doctor_name: item.doctor_name,
                            doctor_profile_picture: item.doctor_profile_picture,
                            fee: item.fee,
                            position: item.position,
                            specialization_name: item.specialization_name,
                            _id: item._id
                        }
                        thisLanguageServiceDoctors.push(everyRecord);
                    });
                    this.ServiceDoctors = thisLanguageServiceDoctors;
                } else if (customerLanguage === 'mm') {
                    let thisLanguageServiceDoctors = [];
                    this.ServiceDoctors = [];
                    this.serviceDoctorsInBothLanguages.forEach((item)=>{
                        let everyRecord = {
                            doctor_id: item.doctor_id,
                            doctor_name: item.doctor_name_mm,
                            doctor_profile_picture: item.doctor_profile_picture,
                            fee: item.fee,
                            position: item.position,
                            specialization_name: item.specialization_name_mm,
                            _id: item._id
                        }
                        thisLanguageServiceDoctors.push(everyRecord);
                    });
                    this.ServiceDoctors = thisLanguageServiceDoctors;
                } else {
                    this.ServiceDoctors = [];
                }
            }
        },
        getDoctorsInfo(endPoint, payload) {
            //"/onLoginDoctorsInfo"

            this.loadingAppointments = false;
            axios.post(process.env.VUE_APP_BACKEND_URL + endPoint, '').then((DoctorsInfoResponse1) => {

                let DoctorsInfoResponse = DoctorsInfoResponse1.data.data;
                this.bannersList = DoctorsInfoResponse.homescreen_banners;
                this.bannersList.forEach((banner) => {
                    this.banners.push({
                        image_url: "https://s3iconimages.mymedicine.com.mm/" + banner.filename,
                        route_to: banner.route_to,
                    });
                });
     
              // Specializations List.
                let thisLanguageSpecialization = [];
                let thisLanguageServiceDoctors = [];
                this.specializationList = [];
                this.ServiceDoctors = [];
                this.specializationlistINBothLanguages = DoctorsInfoResponse.specialization_data;
                this.serviceDoctorsInBothLanguages = DoctorsInfoResponse.service_doctors_data;
                if (this.language && this.language === 'en') {
                    this.setLanguageForOnloginScreen(this.language)
                } else {
                    this.specializationlistINBothLanguages.forEach((item) => {
                        let everyRecord = {
                            image_filename: item.image_filename,
                            specialization_key: item.specialization_key,
                            specialization_name:item.specialization_name_mm,
                            _id: item._id
                        };
                        thisLanguageSpecialization.push(everyRecord);
                    });
                    this.specializationList = thisLanguageSpecialization;
                    if(this.serviceDoctorsInBothLanguages)
                    {
                        this.serviceDoctorsInBothLanguages.forEach((item)=>{
                            let everyRecord = {
                                doctor_id: item.doctor_id,
                                doctor_name: item.doctor_name_mm,
                                doctor_profile_picture: item.doctor_profile_picture,
                                fee: item.fee,
                                position: item.position,
                                specialization_name: item.specialization_name_mm,
                                _id: item._id
                            };
                            thisLanguageServiceDoctors.push(everyRecord);
                        });
                        this.ServiceDoctors = thisLanguageServiceDoctors;
                    } else {
                        this.ServiceDoctors = [];
                    }
                }
                // Highlight Speciality Banners.
                this.highlightSpecialityBannersList = DoctorsInfoResponse.highlight_speciality_banners;
                this.highlightSpecialityBannersList.forEach((banner) => {
                    this.highlightSpecialityBanners.push({
                        image_url: banner.imageUrl,
                        route_to: "FEATURE_SPECIALITY",
                        titleKey: banner.titleKey,
                    });
                });
                this.loadingFlag = false;
            })
            .catch((DoctorsInfoError) => {
                if (payload) {
                    if (DoctorsInfoError.response.status == 401) {
                        this.authErrorFlag = true;
                    } else if (DoctorsInfoError.response.status == 406) {
                        this.restartAppFlag = true;
                    } else if (DoctorsInfoError.response.status == 400) {
                        let userAgent = navigator.userAgent;
                        if (window.xm || userAgent.includes("kbzpay")) {
                            this.restartAppFlag = true;
                        } else {
                            this.errorFlag = true;
                        }
                    } else {
                        this.errorFlag = true;
                    }
                } else {
                    this.loadingFlag = false;
                    this.showWalkInFlag = false;
                }
            });

            axios.post(process.env.VUE_APP_BACKEND_URL + '/getWalkinForHomePage', '').then((HomePageWalkInDoctorList) => {
                // WalkIn Doctors List.
                this.walkInDoctorsListBurmese = HomePageWalkInDoctorList.data.data.walkIn_doctors_data_Burmese
                this.walkInDoctorsListEnglish = HomePageWalkInDoctorList.data.data.walkIn_doctors_data_English
                this.walkInDoctorList = this.language === 'en'? HomePageWalkInDoctorList.data.data.walkIn_doctors_data_English : HomePageWalkInDoctorList.data.data.walkIn_doctors_data_Burmese;
                this.isWalkinLoading = false;
            })
            .catch((HomePageWalkInDoctorListError) =>{
                console.log(HomePageWalkInDoctorListError);
                this.walkIn_doctors_data_Burmese = [];
                this.walkIn_doctors_data_English = [];
                this.isWalkinLoading = false;
            });
        },
        redirectToNewDoctorsPage(docId) {
            let userAgent = navigator.userAgent;
            if (userAgent.includes("kbzpay")) {
                let slicedData = userAgent.slice(userAgent.indexOf("kbzpay")).split('/');
                if (slicedData[slicedData.length-1] < "5.2.3") {
                    this.appVersionFlag = true;
                }
            }
            this.viewedDoctorTableFunction(docId)
            if (!this.appVersionFlag) {
                var walkIn = "walkin";
                this.$router.push({
                    name: 'newBookingPage',
                    params: { docId: docId, walkIn: walkIn}
                });
            }
        },
        drawHeatMap() {
            // let holder = d3.select('#heatMapWrapper').append('svg').attr('width',500).attr('height',350)
            let getAllLogsFromDBBody = {
                token: this.token,
                pageName: 'CustomerHomeScreen'
            };
            axios.post(process.env.VUE_APP_BACKEND_URL + '/getAllLogsFromDB', getAllLogsFromDBBody)
            .then((getAllLogsFromDBResponse) => {
                let dataPoint = getAllLogsFromDBResponse.data.data
                let maxValue =  Math.max.apply(Math, dataPoint.map(function(o) { return o.value; }))
                let minValue =  Math.min.apply(Math, dataPoint.map(function(o) { return o.value; }))
                let heatMapConfig = heatmap.create({
                    container: document.getElementById('heatMapWrapper'),
                    radius: 33,
                    blur: .95
                });
                let sampleData = {
                    max: maxValue,
                    min: minValue,
                    data: dataPoint
                };
                heatMapConfig.setData(sampleData);
            })
            .catch((error)=>{
                console.log(error);
            });
        },
        changeLanguage(language, token) {
            this.isLoading = true;
            let updateLanguageRequest = {
                language: language,
                token : token,
                typeOfUser : 'CUSTOMER'
            };
            axios.post(process.env.VUE_APP_BACKEND_URL+'/updateLanguage', updateLanguageRequest)
            .then(() => {
                this.$i18n.locale = language;
                this.$store.dispatch('changeLocale', this.$i18n.locale);
                this.isLoading = false;
            })
            .catch((changeLanguageError) => {
                this.isLoading = false;
                if(changeLanguageError.response.status == 401){
                    this.authErrorFlag = true;
                }
                else{
                    this.errorFlag = true;
                }
            });
        },
        redirectToKBZPayUpgrade() {
            kbzpay.gotoFunction('native://kbz/customer/upgrade');
        },
        redirectToWalkInDoctors() {
            localStorage.setItem('bookingType', 'walkinAppointment');
            this.$router.push({
                path: '/customer/viewDoctors?type=Walkin&type1=Walkin'
            });
        },
        redirectToServiceDoctors() {
            this.$router.push({
                path: '/customer/viewDoctors?type=ServiceDoctors&type1=ServiceDoctors'
            });
        },
        redirectToBookAppointment(doctor) {
            // Page not developed.
        },
        redirectCareComapnionInfo() {
            this.$router.push({
                name: "CareCompanionInfo",
            });
        },
        redirectToCareCompanion() {
            if (this.token) {
                this.$router.push({
                    name: "careCompanion"
                });
            } else {
                this.$router.push({
                    name : "Login"
                });
            }
        },
        closeAuthErrorDialog() {
            this.authErrorFlag = false;
            this.$router.push({
                name: "Login",
            });
        },
        closeAppVersionFlag() {
            kbzpay.gotoFunction('native://kbz/customer/about');
        },
        closeMyMedicine() {
            kbzpay.gotoFunction('native://kbz/customer/homepage/wallet');
        },
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName: 'teleconsultationHomePage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'teleconsultationHomePage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        goToSearchPage() {
            this.$router.push({
                name: "SearchPage"
            });
        },
        highlightSpecialitySelected(){
        },
        navigateToPackages() {
            this.$router.push({
                name: 'Packages'
            })
        },
        viewedDoctorTableFunction(doctorId) {
            let token = this.$cookies.get("customerToken") === null ? '': this.$cookies.get("customerToken") ;
            let viewedDoctorTableBody = {
                doctorId: doctorId,
                token: token,
                typeOfUser: 'CUSTOMER',
                pageName: 'CustomerHomeScreen_walkIn'
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+'/viewedDoctorTable', viewedDoctorTableBody)
            .then((viewedDoctorTableResponse) => {
                console.log('viewedDoctorTableResponse', viewedDoctorTableResponse);
            })
            .catch((viewedDoctorTableError) => {
                console.log('viewedDoctorTableError', viewedDoctorTableError);
            })
        },
    }
}
</script>
<style>
.custom-icon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
}
.overlayClass {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    cursor: pointer;
}
.desktopLayoutClass{
    max-width: 425px;
    /* margin-left: auto !important;
    margin-right: auto !important; */
}

.headertext{
    font-size: 18px;
    font-weight: bold;
}

.heading{
    font-size: 14px;
    font-weight: bold;
}

.subheading{
    font-size: 14px;
}

.normaltext{
    font-size: 12px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
</style>

<style scoped>
.teleconsultationBottomBar {
    @media (min-width: 425px) {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 425px;
    }
}
.v-bottom-navigation--fixed {
    left: auto !important
}
.mobile-bottom-nav-bar {
    @media (max-width: 600px) {
        
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        display: block !important;

    }

    @media (min-width: 1265px) {
        display: block !important;
    }
}
.v-text-field--outlined :deep(fieldset) {
  border-color: #E4F5FF !important;
  color: #E4F5FF !important;
}
.specializationComponentClass{
    justify-content: space-evenly;
}
.activePackageIndicationBanner {
 
padding: 16px;

/* width: 335px; */
height: auto;

background: rgba(248, 144, 53, 0.16);
border-radius: 8px;
display: flex;
align-items: center;
/* Inside auto layout */
}
.activePackageText {
width: 190px;
height: 16px;
margin-left: 10px;

/* 14 - Semi Bold */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


/* Orange Grad */

color: #F89035;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;

}
.v-slide-group--active-slide :deep(.v-slide-group__wrapper) {
  touch-action: pan-x !important;
}

.v-slide-group--active-slide :deep(.v-slide-group__wrapper::-webkit-scrollbar) {
  width: 0px;
  height: 0px;
  touch-action: pan-x !important;
}
.offer_indication {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
    gap: 4px;
    width: 100%;
    height: 20px;
    background: #EB5757;
    backdrop-filter: blur(1px);
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;
}
.offer_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}
</style>